import { ConsentComponent, ShopifyConfig } from '@ifixit/consent-banner';
import { initializeChakraComponent } from 'Shared/chakra-initialize';
import { Text, Link, UnorderedList, ListItem } from '@chakra-ui/react';

const translatedText = (privacyPolicyUrl: string) => ({
   'buttonText': {
      acceptAll: _js('Accept All'),
      rejectAll: _js('Reject All'),
      details: _js('Details'),
   },
   'intro': (
      <>
         {
            /* Translators: String ends with 'Privacy Policy.' */
            _js(
               'iFixit uses cookies to enhance site functionality and analyze traffic. By using our site, you agree to our cookie use and '
            )
         }
         <Link href={privacyPolicyUrl} size="inherit" textDecoration={'underline'}>
            {_js('Privacy Policy.')}
         </Link>
      </>
   ),
   'details': (
      <>
         <Text m={0}>{_js('Our cookies fall under the following categories:')}</Text>
         <UnorderedList m={0} mt={1} pl={6}>
            <ListItem>{_js('Analytics')}</ListItem>
            <ListItem>{_js('Marketing')}</ListItem>
            <ListItem>{_js('Conversion tracking')}</ListItem>
         </UnorderedList>
      </>
   ),
});

function CarpenterConsentComponent({
   isTest,
   flagEnabled,
   privacyPolicyUrl = '/Info/Privacy',
   piwikSiteId,
   needsConsent,
   shopifyConfig,
}: {
   isTest: boolean;
   flagEnabled: boolean;
   privacyPolicyUrl?: string;
   piwikSiteId: string;
   needsConsent: boolean;
   shopifyConfig: ShopifyConfig;
}) {
   if (isTest && !flagEnabled) {
      return null;
   }

   const text = translatedText(privacyPolicyUrl);
   return (
      <ConsentComponent
         buttonText={text.buttonText}
         intro={text.intro}
         details={text.details}
         needsConsent={needsConsent}
         piwikSiteId={piwikSiteId}
         shopifyConfig={shopifyConfig}
      />
   );
}

initializeChakraComponent('ConsentBanner', CarpenterConsentComponent);
