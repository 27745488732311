import initializeComponent from 'Shared/react-initialize';
import { ChakraDefaultProvider } from 'Shared/chakra-provider';
import { ComponentType } from 'react';

export function Initializer<T extends JSX.IntrinsicAttributes>(Component: ComponentType<T>) {
   // eslint-disable-next-line react/display-name -- FIXME: Component definition is missing display name
   return (props: T) => (
      <ChakraDefaultProvider>
         <Component {...props} />
      </ChakraDefaultProvider>
   );
}

export function initializeChakraComponent(name: any, Component: any, defaultProps = {}) {
   initializeComponent(name, Initializer(Component), defaultProps);
}
